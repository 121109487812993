// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("./../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-build-js": () => import("./../src/pages/build.js" /* webpackChunkName: "component---src-pages-build-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-build-js": () => import("./../src/templates/build.js" /* webpackChunkName: "component---src-templates-build-js" */),
  "component---src-templates-plan-js": () => import("./../src/templates/plan.js" /* webpackChunkName: "component---src-templates-plan-js" */),
  "component---src-templates-program-js": () => import("./../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */)
}

